
  import Vue from "vue";
  import Component from "vue-class-component";
  import EmployeeCreateDialogFormCard from "./EmployeeCreateDialogFormCard.vue";
  import EmployeeEmploymentForm from "@/views/Employee/forms/EmployeeEmploymentForm.vue";
  import EmployeeExportForm from "../forms/EmployeeExportForm.vue";
  import EmployeePersonalForm from "../forms/EmployeePersonalForm.vue";
  import EmployeeWageForm from "../forms/EmployeeWageForm.vue";
  import ModalDialog from "@/components/ModalDialog.vue";

  import Employee from "@/model/Employee";
  import Wage from "@/model/Wage";
  import EmployeeService from "@/service/EmployeeService";
  import Employment from "@/model/Employment";
  import {MessageTypes} from "@/components/message-types";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import InventoriesService from "@/service/InventoriesService";
  import PageUtils from "@/components/PageUtils";
  import {CompanyLocation} from "@/model/CompanyLocation";

  @Component({
    components: {
      EmployeeCreateDialogFormCard,
      EmployeeEmploymentForm,
      EmployeeExportForm,
      EmployeePersonalForm,
      EmployeeWageForm,
      ModalDialog
    }
  })
  export default class EmployeeCreateDialog extends Vue {
    private employee: Employee = new Employee();
    private companyLocations: Array<CompanyLocation> = new Array<CompanyLocation>();
    private errors: Map<string, string> = new Map<string, string>();
    private dialog = false;
    private registrationUuid: string | undefined;

    get employeeErrors(): Map<string, string> {
      const errors = new Map<string, string>();
      this.errors.forEach((value, key) => {
        if (key.startsWith("employee.")) {
          // Only pass the prop name after employee.
          errors.set(key.split("employee.")[1], value);
        }
        if (!key.includes(".")) {
          errors.set(key, value);
        }
      });

      return errors;
    }

    get wageErrors(): Map<string, string> {
      const errors = new Map<string, string>();
      this.errors.forEach((value, key) => {
        if (key.startsWith("wage.")) {
          // Only pass the prop name after wage.
          errors.set(key.split("wage.")[1], value);
        }
      });

      return errors;
    }

    get employmentErrors(): Map<string, string> {
      const errors = new Map<string, string>();
      this.errors.forEach((value, key) => {
        if (key.startsWith("employment.")) {
          // Only pass the prop name after employment.
          errors.set(key.split("employment.")[1], value);
        }
      });

      return errors;
    }

    mounted() {
      this.$eventBus.$on(MessageTypes.OPEN_EMPLOYEE_CREATE, (defaults: Employee | string) => {
        this.dialog = true;
        this.errors = new Map<string, string>();
        this.employee = new Employee();

        this.loadCompanyLocations();

        if (typeof defaults === "string") {
          this.registrationUuid = defaults;

          EmployeeService.getDefaultData(defaults)
            .then(response => {
              this.employee = Object.assign(new Employee(), response.data);
              this.employee.wages = this.employee.wages
                .map(wage => Object.assign(new Wage(), wage));
              this.employee.employments = this.employee.employments
                .map(employment => Object.assign(new Employment(), employment));
              // By default all employees should initially be exported
              this.employee.export = true;
            });
        } else {
          EmployeeService.getDefaultData()
            .then(response => {
              this.employee = Object.assign(new Employee(), defaults, response.data);
              this.employee.wages = this.employee.wages
                .map(wage => Object.assign(new Wage(), wage));
              this.employee.employments = this.employee.employments
                .map(employment => Object.assign(new Employment(), employment));
              // By default all employees should initially be exported
              this.employee.export = true;
            });
        }
      });
    }

    loadCompanyLocations() {
      InventoriesService.findCompanyLocationsByTerm("")
        .then(response => {
          this.companyLocations = PageUtils.extractCollection(response.data, "companyLocations");
        });
    }

    beforeDestroy() {
      this.$eventBus.$off(MessageTypes.OPEN_EMPLOYEE_CREATE, () => {
        this.closeDialog();
      });
    }

    closeDialog() {
      this.dialog = false;
      this.employee = new Employee();
      this.errors = new Map<string, string>();
    }

    save() {
      this.errors = new Map<string, string>();

      EmployeeService.createEmployee(this.employee, this.registrationUuid)
        .then(response => {
          this.$eventBus.$emit(MessageTypes.SUCCESS, "Mitarbeiter erstellt.", 1000);

          if (!this.registrationUuid) {
            this.createWelcomePdf(response.data.employeeId);
          }
          this.closeDialog();
        })
        .catch((error: AxiosError) => {
          if (error && error.response && error.response.status === 422) {
            this.$eventBus.$emit(MessageTypes.WARNING, "Bitte Eingaben prüfen.");

            const errorMap = new Map<string, string>();
            // @ts-ignore
            error.response.data.errors.forEach((error: ValidationError) => {
              errorMap.set(error.path, error.message);
            });

            // Needed to trigger reactivity
            this.errors = errorMap;
          } else {
            this.$eventBus.$emit(MessageTypes.ERROR, "Mitarbeiter konnte nicht erstellt werden.");
          }
        });
    }

    createWelcomePdf(employeeId: number) {
      EmployeeService.createWelcomePdf(employeeId)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // @ts-ignore
            link.setAttribute("download", response.headers.filename);
            document.body.appendChild(link);
            link.click();

            if (this.isAuthorized("Superadmin")) {
              this.$router.push({
                name: "EmployeeDetails",
                params: {employeeId: employeeId.toString()}
              });
            }
          }
        });
    }
  }
