import {SelectionItem} from "@/core/Selection";
import {ref} from "vue";

export enum EmployeeStatus {
  ACTIVE= "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum OrderType {
  INV = "INV",
  MFR = "MFR",
  DRY = "DRY",
  BEV = "BEV",
  MOD = "MOD",
  MIS = "MIS"
}

export enum CashFlowType {
  IN = "IN",
  OUT = "OUT"
}

export enum RegistrationType {
  MINOR_EMPLOYMENT = "MINOR_EMPLOYMENT",
  MODIFICATION_HELPER = "MODIFICATION_HELPER",
  SHORT_TERM = "SHORT_TERM",
  SOCIAL_SECURITY = "SOCIAL_SECURITY",
  STOCK_REPLENISHMENT_SOCIAL_SECURITY = "STOCK_REPLENISHMENT_SOCIAL_SECURITY",
  STOCK_REPLENISHMENT_MINOR_EMPLOYMENT = "STOCK_REPLENISHMENT_MINOR_EMPLOYMENT",
  WORKING_STUDENT = "WORKING_STUDENT"
}

export enum WageType {
  WAG = "WAG",
  SAL = "SAL"
}

export const WageTypes = [
  {
    code: WageType.WAG,
    name: "Wage",
    localizedName: "Stundenlohn"
  },
  {
    code: WageType.SAL,
    name: "Salaray",
    localizedName: "Gehalt"
  }
];

export enum WorklogType {
  WORK = "WORK",
  PAUSE = "PAUSE",
  TRAVEL = "TRAVEL",
  PASSENGER = "PASSENGER",
}

export const WorklogTypes = [
  {
    code: WorklogType.WORK,
    name: "Work",
    localizedName: "Arbeit"
  },
  {
    code: WorklogType.PAUSE,
    name: "Pause",
    localizedName: "Pause"
  },
  {
    code: WorklogType.TRAVEL,
    name: "Travel",
    localizedName: "Reise"
  },
  {
    code: WorklogType.PASSENGER,
    name: "Passenger",
    localizedName: "Mitfahrer"
  }
];

export const DocumentTypes = [
  {
    code: "PAYROLL",
    name: "Entgeltabrechnung"
  },
  {
    code: "INCOME_TAX",
    name: "Lohnsteuerbescheinigung"
  }
];

export function cashFlowTypeSelectionItems () {
  const cashFlowTypes = ref<Array<SelectionItem>>([
    {
      code: CashFlowType.IN,
      name: "Einnahmen"
    },
    {
      code: CashFlowType.OUT,
      name: "Ausgaben"
    }
  ]);

  return {
    cashFlowTypes
  };
}

export const CashFlowTypesOld = [
  {
    code: CashFlowType.IN,
    name: "Einnahmen"
  },
  {
    code: CashFlowType.OUT,
    name: "Ausgaben"
  }
];

export enum EmploymentType {
  FUL = "FUL",
  MIN = "MIN",
  SHO = "SHO",
  WORKING_STUDENT = "WORKING_STUDENT"
}

export const EmploymentTypes = [
  {
    code: EmploymentType.FUL,
    name: "Fulltime",
    localizedName: "Vollzeit"
  },
  {
    code: EmploymentType.MIN,
    name: "Minor",
    localizedName: "Geringfügig"
  },
  {
    code: EmploymentType.SHO,
    name: "Short term",
    localizedName: "Kurzfristig"
  },
  {
    code: EmploymentType.WORKING_STUDENT,
    name: "Working student",
    localizedName: "Werkstudent"
  }
];

export const OrderTypes = [
  {
    code: "INV",
    name: "Inventory",
    project: true,
    localizedName: "Inventuren",
    resourceKey: "OrderType.INV"
  },
  {
    code: "DRY",
    name: "Dry Assortment",
    project: false,
    localizedName: "Trockensortiment",
    resourceKey: "OrderType.DRY"
  },
  {
    code: "BEV",
    name: "Beverage",
    project: false,
    localizedName: "Getränke",
    resourceKey: "OrderType.BEV"
  },
  {
    code: "MOD",
    name: "Modification",
    project: true,
    localizedName: "Umbauten",
    resourceKey: "OrderType.MOD"
  },
  {
    code: "MIS",
    name: "Misc",
    project: true,
    localizedName: "Sonstige",
    resourceKey: "OrderType.MIS"
  },
  {
    code: "MFR",
    name: "Mirror Flatrate",
    project: true,
    localizedName: "Spiegelflatrate",
    resourceKey: "OrderType.MFR"
  }
];

export enum AsyncState {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}
