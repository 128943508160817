
  import {defineComponent, PropType, reactive, SetupContext, toRefs} from "vue";
  import Employee from "@/model/Employee";
  import {earliestValidFrom} from "@/store/StoreUtils";
  import {today} from "@/DateUtils";

  export default defineComponent({
    name: "EmployeeExportForm",
    components: {},
    props: {
      employee: {
        type: Object as PropType<Employee>,
        default: () => new Employee()
      },
      errors: {
        type: Map as PropType<Map<string, string>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context: SetupContext) {
      const initialState = {
        // This is a hack where we directly mutate a prop, but since this form is tightly coupled with the main employee
        // model in the parent classes
        model: props.employee
      };

      const state = reactive({...initialState});
      return {
        ...toRefs(state),
        earliestValidFrom,
        today
      };
    }
  });
