import {EmploymentType, WageType} from "@/enums";
import Wage from "@/model/Wage";
import Employment from "@/model/Employment";
import {CompanyLocation} from "@/model/CompanyLocation";

export default class Employee {
  public id!: number;

  public employeeId!: number;

  public firstName: string | null = null;

  public lastName: string | null = null;

  public birthdate: string | null = "";

  public email: string | null = null;

  public companyLocation: CompanyLocation | null = null;

  public name: string;

  public teamleadSince: string;

  public firstAidCourseDate: string;

  public firstAidValidUntil: string;

  public active: boolean;

  public manager: boolean;

  public export: boolean;

  public wage?: number;

  public wages: Array<Wage> = new Array<Wage>();

  public wageType?: WageType;

  public employmentType?: EmploymentType;

  public employments: Array<Employment> = new Array<Employment>();

  public contractExpiryDate?: string;

  public operatorNumber?: number;

  public inventoryBonusEligible?: boolean;

  public isInventoryBonusEligibleSince: string;

  public temporary?: boolean;


  public getFullName(): string {
    return this.firstName + " " + this.lastName;
  }

  public get fullName(): string {
    return this.firstName + " " + this.lastName;
  }
}
